import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { forwardRef } from 'react';
import { graphql } from '~/apollo/generated/v4';
import type { SelectProps } from '~/components/ui/forms/Select';
import { Select } from '~/components/ui/forms/Select';

export const PICTURE_TYPES = graphql(`
  query PictureTypeSelectOptions {
    pictureType: outcropEnumerations(type: PICTURE_TYPE) {
      values
    }
  }
`);

type Props = Omit<SelectProps, 'options'>;

export const PictureTypeSelect = forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    const { data, loading } = useQuery(PICTURE_TYPES, {
      fetchPolicy: 'cache-first',
    });

    const options = R.sortBy(R.identity, data?.pictureType.values ?? []);

    return (
      <Select
        {...props}
        ref={ref}
        options={options}
        disabled={loading || props.disabled}
      />
    );
  },
);
PictureTypeSelect.displayName = 'PictureTypeSelect';
