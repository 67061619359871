import {
  useCallback,
  useState,
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  type ReactNode,
} from 'react';
import { cn } from '~/utils/common';

// Can either be a controlled checkbox or only an onToggle cb is provided
type CheckEvent = ChangeEventHandler<HTMLInputElement>;
type CheckboxControls =
  | { open: boolean; onToggle: CheckEvent }
  | { open?: never; onToggle?: CheckEvent };

type ComponentProps = {
  icon?: 'arrow' | 'plus';
  title: ReactNode;
  titleClassName?: string;
  contentClassName?: string;
};

export type CollapseProps = ComponentProps &
  CheckboxControls &
  Omit<ComponentPropsWithoutRef<'div'>, 'title'>;

export function Collapse({
  icon,
  title,
  className,
  titleClassName,
  contentClassName,
  open,
  onToggle,
  children,
}: CollapseProps) {
  return (
    <div
      className={cn(
        'collapse',
        {
          'collapse-arrow': icon === 'arrow',
          'collapse-plus': icon === 'plus',
        },
        className,
      )}
    >
      <input type="checkbox" checked={open} onChange={onToggle} />
      <div className={cn('collapse-title', titleClassName)}>{title}</div>
      <div className={cn('collapse-content', contentClassName)}>{children}</div>
    </div>
  );
}

export function CollapsibleCard({ title, ...collapseProps }: CollapseProps) {
  return (
    <div className="card card-border shadow-sm">
      <div className="card-body p-2">
        <Collapse
          icon="arrow"
          title={
            <h3 className="card-title flex justify-between items-center gap-4">
              {title}
            </h3>
          }
          {...collapseProps}
        />
      </div>
    </div>
  );
}

export function useControlledCollapsibleCard(initialOpen = false) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const toggleOpen = useCallback(() => setIsOpen(prev => !prev), []);

  return {
    open: isOpen,
    setIsOpen,
    onToggle: toggleOpen,
  };
}
