import type { ComponentPropsWithRef } from 'react';
import { forwardRef } from 'react';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { LifecycleStatusIcon } from '~/components/common/icons/LifecycleStatusIcon';
import { type FormLabelProps } from '~/components/ui/forms/FormLabel';
import { InputBase } from '~/components/ui/forms/InputBase';
import { readableGqlEnum } from '~/utils/text';

type Props = ComponentPropsWithRef<'select'> & {
  label?: FormLabelProps['label'];
};

export const LifecycleStatusSelect = forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    function validateStatus(status: unknown): status is LifecycleStatus {
      return Object.values(LifecycleStatus).some(val => val === status);
    }
    const value = props.value ?? props.defaultValue;
    const isStatus = validateStatus(value);

    return (
      <InputBase
        name={props.name ?? 'lifecycleStatus'}
        label={props.label}
        required={props.required}
      >
        <div className="select w-full">
          {isStatus && <LifecycleStatusIcon status={value} className="mr-4" />}
          <select {...props} ref={ref} className="ml-4 grow">
            {[
              LifecycleStatus.Draft,
              LifecycleStatus.ReadyForApproval,
              LifecycleStatus.Approved,
              LifecycleStatus.Published,
            ].map(value => (
              <option
                key={value}
                value={value}
                label={readableGqlEnum(value)}
              />
            ))}
          </select>
        </div>
      </InputBase>
    );
  },
);
LifecycleStatusSelect.displayName = 'LifestyleStatusSelect';
