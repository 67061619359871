import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { Outlet, useOutletContext } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { OutcropPanelsPageQuery } from '~/apollo/generated/v4/graphql';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useRouteParam } from '~/hooks/routing';
import type { RefetchQueries } from '~/utils/graphql';
import { createRefetchQueries } from '~/utils/graphql';

const OUTCROP_PANELS_PAGE = graphql(`
  query OutcropPanelsPage($id: ID!) {
    outcropGet(id: $id) {
      id
      panels {
        ...panelParts
      }
    }
  }
`);

export default function OutcropPanelsPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

type Outcrop = NonNullable<OutcropPanelsPageQuery['outcropGet']>;
type Panel = Outcrop['panels'][number];

type OutletContext = {
  outcropId: number;
  panels: Panel[];
  refetchQueries: RefetchQueries;
};

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(OUTCROP_PANELS_PAGE, {
    variables: { id: outcropId },
  });

  const refetchQueries = createRefetchQueries([
    { query: OUTCROP_PANELS_PAGE, variables: { id: outcropId } },
  ]);

  const outcrop = data?.outcropGet;
  const panels = R.sortWith(
    [R.ascend(p => p.priority ?? Infinity), R.ascend(p => p.name)],
    outcrop?.panels ?? [],
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  if (!panels.length) {
    return <NoItemsAlert entity="analysed panels" />;
  }

  const outletContext: OutletContext = {
    outcropId,
    panels,
    refetchQueries,
  };

  return <Outlet context={outletContext} />;
}

export const useOutcropPanelsOutletContext = () =>
  useOutletContext<OutletContext>();
