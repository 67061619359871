import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faPencil, faScroll, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { gql } from '~/apollo/client-v3';
import * as fragments from '~/apollo/fragments';
import type {
  BookmarkCollectionPartsFragment,
  CollectionPageQuery,
  CollectionPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { BookmarkList } from '~/components/bookmark/BookmarkList';
import { AddCollectionToReportModal } from '~/components/bookmark/CreateReportFromCollection';
import { DeleteBookmarkCollection } from '~/components/bookmark/DeleteBookmarkCollection';
import { BookmarkCollectionIcon } from '~/components/bookmark/icons';
import { UpdateCollectionModal } from '~/components/bookmark/UpdateCollectionModal';
import { Heading } from '~/components/common/Heading';
import { LocalDate } from '~/components/common/LocalDate';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { useAuth } from '~/contexts/auth';
import { useMySafariOutletContext } from '~/routes/my-safari';
import {
  canEditCollection,
  isCompanyCollection,
} from '~/utils/modules/bookmark';

const COLLECTION_PAGE = gql`
  query CollectionPage($collectionId: Int!) {
    bookmarkCollectionList(collectionId: $collectionId) {
      ...bookmarkCollectionParts
      user {
        ...publicUserParts
      }
      bookmarks {
        ...bookmarkCollectionBookmarkParts
        bookmark {
          ...bookmarkParts
          company {
            ...companyParts
          }
          parent {
            ...bookmarkParentParts
          }
          target {
            ...bookmarkTargetParts
          }
          user {
            ...publicUserParts
          }
          collections {
            ...bookmarkCollectionParts
          }
        }
      }
    }
  }

  ${fragments.bookmarkCollectionParts}
  ${fragments.bookmarkCollectionBookmarkParts}
  ${fragments.companyParts}
  ${fragments.bookmarkParts}
  ${fragments.bookmarkParentParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.publicUserParts}
`;

export default function CollectionRoute() {
  const ctx = useMySafariOutletContext();

  const { authority } = useAuth();
  invariant(authority, 'Must be logged in');

  const params = useParams<{ collectionId: string }>();
  invariant(params.collectionId, 'collectionId param required');
  const collectionId = parseInt(params.collectionId);

  const { data, loading } = useQuery<
    CollectionPageQuery,
    CollectionPageQueryVariables
  >(COLLECTION_PAGE, { variables: { collectionId } });

  const collectionList = data?.bookmarkCollectionList ?? [];
  const collection = collectionList.find(c => c.id === collectionId);

  // Reload both the navigation and the bookmark
  const refetchQueries: [
    ...PureQueryOptions[],
    PureQueryOptions<CollectionPageQueryVariables>,
  ] = [
    ...ctx.refetchQueries,
    {
      query: COLLECTION_PAGE,
      variables: { collectionId },
    },
  ];

  // Create a collection list to be made available to bookmarks
  // All collections are passed to this page, so we need to determine here whether
  // it is a personal or company collection, and filter the available collections accordingly
  let availableCollections: BookmarkCollectionPartsFragment[];
  if (collection?.companyId) {
    availableCollections = ctx.allCollections.filter(c => c.companyId !== null);
  } else if (collection?.companyId === null) {
    availableCollections = ctx.allCollections.filter(c => c.companyId === null);
  } else {
    availableCollections = [];
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!collection) return <NotFound />;

  return (
    <>
      <Panel>
        <Panel.Body>
          <Heading level={2} className="flex justify-start gap-2 items-center">
            <BookmarkCollectionIcon isCompany={!!collection.companyId} />
            <span>{collection.name}</span>
          </Heading>

          <div className="text-base text-muted">
            Last Updated{' '}
            {isCompanyCollection(collection) && (
              <>by {collection.user.name} - </>
            )}
            <LocalDate date={collection.updatedAt} />
          </div>

          <p className="whitespace-pre-line text-base my-2">
            {collection.description}
          </p>
        </Panel.Body>

        <Panel.Footer className="p-1 flex justify-between">
          <div>
            <AddCollectionToReportModal
              collectionId={collection.id}
              isCompany={!!collection.companyId}
            >
              {showModal => (
                <Button
                  type="button"
                  onClick={showModal}
                  color="ghost"
                  size="sm"
                  startIcon={<FontAwesomeIcon icon={faScroll} />}
                >
                  Create Report from Collection
                </Button>
              )}
            </AddCollectionToReportModal>
          </div>

          <div className="text-right">
            <UpdateCollectionModal collection={collection}>
              {showModal => (
                <Button
                  type="button"
                  color="ghost"
                  size="sm"
                  onClick={showModal}
                  startIcon={<FontAwesomeIcon icon={faPencil} />}
                >
                  Edit
                </Button>
              )}
            </UpdateCollectionModal>

            {canEditCollection(authority, collection) && (
              <DeleteBookmarkCollection
                collection={collection}
                refetchQueries={refetchQueries}
              >
                {confirmDelete => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    className="gap-1"
                    onClick={confirmDelete}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeleteBookmarkCollection>
            )}
          </div>
        </Panel.Footer>
      </Panel>

      <div className="clear-both mb-4" />

      <Heading level={3}>Bookmarks</Heading>

      {!collection.bookmarks.length && (
        <div className="text-muted mt-4">
          <p>This collection doesn't contain any bookmarks.</p>
        </div>
      )}

      <BookmarkList
        bookmarks={collection.bookmarks.map(bcbm => bcbm.bookmark)}
        collections={availableCollections}
        refetchQueries={refetchQueries}
        sortStateKey={`bookmarkCollection-${collection.id}`}
      />
    </>
  );
}
