import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import type {
  OutcropPanelsPageQuery,
  PanelPicturePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PictureThumbnailV4 } from '~/components/common/picture-thumbnail-v4';
import { NavTabs } from '~/components/navigation/NavTabs';
import { Button } from '~/components/ui/button';
import {
  CollapsibleCard,
  useControlledCollapsibleCard,
} from '~/components/ui/collapse';
import { useRouteParam } from '~/hooks/routing';
import { panelDetailRoute } from '~/paths';
import { useOutcropPanelsOutletContext } from '~/routes/outcrop/$outcropId/panels';
import { cn } from '~/utils/common';
import type { RefetchQueries } from '~/utils/graphql';

type Outcrop = NonNullable<OutcropPanelsPageQuery['outcropGet']>;
type Panel = Outcrop['panels'][number];

export default function OutcropPanelDetailPage() {
  const { outcropId, panels, refetchQueries } = useOutcropPanelsOutletContext();
  const panelId = useRouteParam('panelId', parseInt);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const panel = panels.find(p => parseInt(p.id) === panelId);

  if (!panel) {
    return <NotFound />;
  }

  return (
    <div className="space-y-4">
      <NavTabs>
        {panels.map(panel => (
          <NavTabs.Tab
            key={panel.id}
            to={panelDetailRoute(outcropId, parseInt(panel.id))}
          >
            {panel.name}
          </NavTabs.Tab>
        ))}
      </NavTabs>

      <div className="grid lg:grid-cols-12 gap-4">
        <div className="lg:col-span-9">
          <div>{panel.description}</div>
          <div>Author: {panel.author}</div>
        </div>

        <div className="lg:col-span-3 flex justify-end items-end">
          <Button
            type="button"
            size="sm"
            color="ghost"
            startIcon={
              <FontAwesomeIcon
                icon={faArrowRightToBracket}
                rotation={isSidebarOpen ? undefined : 180}
              />
            }
            block
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            Toggle Sidebar
          </Button>
        </div>
      </div>

      <div className="grid lg:grid-cols-12 gap-4">
        <div
          className={cn('space-y-4', {
            'lg:col-span-9': isSidebarOpen,
            'lg:col-span-12': !isSidebarOpen,
          })}
        >
          <PanelPicture
            title="Original Panel"
            picture={panel.originalPanel}
            refetchQueries={refetchQueries}
          />
          <PanelPicture
            title="Interpreted Panel"
            picture={panel.interpretedPanel}
            refetchQueries={refetchQueries}
          />
          <PanelPicture
            title="Rock Clean"
            picture={panel.rockCleanedPanel}
            refetchQueries={refetchQueries}
          />
        </div>

        {isSidebarOpen && (
          <div className="lg:col-span-3 bg-slate-50 p-2 space-y-4">
            <div>
              <Heading level={3}>Panel Dimensions</Heading>
              <div>Width: {panel.width} meters</div>
              <div>Height: {panel.height} meters</div>
            </div>

            {panel.legendKey && (
              <div>
                <Heading level={3}>Legend Key</Heading>
                <PictureThumbnailV4
                  picture={panel.legendKey}
                  imgSrc={panel.legendKey.file.signedUrl}
                  refetchQueries={refetchQueries}
                />
              </div>
            )}

            {panel.elementProportion && (
              <div>
                <Heading level={3}>Element Proportions</Heading>
                <PictureThumbnailV4
                  picture={panel.elementProportion}
                  imgSrc={panel.elementProportion.file.signedUrl}
                  refetchQueries={refetchQueries}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <hr />

      <StatisticsSection panel={panel} refetchQueries={refetchQueries} />
    </div>
  );
}

type PanelPictureProps = {
  title: string;
  picture: PanelPicturePartsFragment | null | undefined;
  refetchQueries: RefetchQueries;
};

function PanelPicture({ title, picture, refetchQueries }: PanelPictureProps) {
  const cardControl = useControlledCollapsibleCard(true);

  if (!picture) return null;

  return (
    <CollapsibleCard {...cardControl} title={title}>
      <PictureThumbnailV4
        picture={picture}
        imgSrc={picture.file.signedUrl}
        refetchQueries={refetchQueries}
      />
    </CollapsibleCard>
  );
}

type StatisticsTab =
  | 'proportionCurves'
  | 'proportionRanges'
  | 'semiVariograms'
  | 'transitionStatistics';

function StatisticsSection({
  panel,
  refetchQueries,
}: {
  panel: Panel;
  refetchQueries: RefetchQueries;
}) {
  const hasProportionCurves = Boolean(
    panel.lateralProportionCurve || panel.verticalProportionCurve,
  );
  const hasProportionRanges = Boolean(
    panel.proportionRangeTable || panel.proportionRangeVertical,
  );
  const hasSemiVariograms = Boolean(
    panel.variogramParameterTable ||
      panel.verticalVariograms ||
      panel.horizontalVariograms,
  );
  const hasTransitionStatistics = Boolean(panel.transitionStatistics);

  const initialTab: StatisticsTab = hasProportionCurves
    ? 'proportionCurves'
    : hasProportionRanges
      ? 'proportionRanges'
      : hasSemiVariograms
        ? 'semiVariograms'
        : hasTransitionStatistics
          ? 'transitionStatistics'
          : 'proportionCurves';

  const [tab, setTab] = useState<StatisticsTab>(initialTab);

  if (
    !hasProportionCurves &&
    !hasProportionRanges &&
    !hasSemiVariograms &&
    !hasTransitionStatistics
  ) {
    return null;
  }

  const panelProps = { panel, refetchQueries };

  return (
    <div className="space-y-4">
      <div className="tabs tabs-box inline-block">
        <input
          type="radio"
          name="proportionCurves"
          className="tab"
          aria-label="Proportion curves"
          checked={tab === 'proportionCurves'}
          onChange={() => setTab('proportionCurves')}
          disabled={!hasProportionCurves}
        />
        <input
          type="radio"
          name="proportionRanges"
          className="tab"
          aria-label="Proportion ranges"
          checked={tab === 'proportionRanges'}
          onChange={() => setTab('proportionRanges')}
          disabled={!hasProportionRanges}
        />
        <input
          type="radio"
          name="semiVariograms"
          className="tab"
          aria-label="Semi-variograms"
          checked={tab === 'semiVariograms'}
          onChange={() => setTab('semiVariograms')}
          disabled={!hasSemiVariograms}
        />
        <input
          type="radio"
          name="transitionStatistics"
          className="tab"
          aria-label="Transition statistics"
          checked={tab === 'transitionStatistics'}
          onChange={() => setTab('transitionStatistics')}
          disabled={!hasTransitionStatistics}
        />
      </div>

      {tab === 'proportionCurves' && <ProportionCurves {...panelProps} />}
      {tab === 'proportionRanges' && <ProportionRanges {...panelProps} />}
      {tab === 'semiVariograms' && <SemiVariograms {...panelProps} />}
      {tab === 'transitionStatistics' && (
        <TransitionStatistics {...panelProps} />
      )}
    </div>
  );
}

function ProportionCurves({
  panel,
  refetchQueries,
}: {
  panel: Panel;
  refetchQueries: RefetchQueries;
}) {
  return (
    <div className="space-y-4">
      <div>
        <Heading level={3}>Proportion Curves</Heading>
        <p>
          Shows the proportion of elements in a series of vertical and
          horizontal profiles
        </p>
      </div>

      <div className="grid lg:grid-cols-12 gap-4">
        <div className="lg:col-span-9">
          {panel.lateralProportionCurve && (
            <div>
              <PictureThumbnailV4
                picture={panel.lateralProportionCurve}
                imgSrc={panel.lateralProportionCurve.file.signedUrl}
                refetchQueries={refetchQueries}
              />
              <p>Lateral proportion curve</p>
            </div>
          )}
        </div>
        <div className="lg:col-span-3">
          {panel.verticalProportionCurve && (
            <div>
              <PictureThumbnailV4
                picture={panel.verticalProportionCurve}
                imgSrc={panel.verticalProportionCurve.file.signedUrl}
                refetchQueries={refetchQueries}
              />
              <p>Vertical proportion curve</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ProportionRanges({
  panel,
  refetchQueries,
}: {
  panel: Panel;
  refetchQueries: RefetchQueries;
}) {
  return (
    <div className="space-y-4">
      <div>
        <Heading level={3}>Proportion Ranges</Heading>
        <p>Shows the spread of proportions in vertical profiles</p>
      </div>

      <div className="grid lg:grid-cols-12 gap-4">
        <div className="lg:col-span-6">
          {panel.proportionRangeVertical && (
            <div>
              <PictureThumbnailV4
                picture={panel.proportionRangeVertical}
                imgSrc={panel.proportionRangeVertical.file.signedUrl}
                refetchQueries={refetchQueries}
              />
            </div>
          )}
        </div>
        <div className="lg:col-span-6">
          {panel.proportionRangeTable && (
            <div>
              <PictureThumbnailV4
                picture={panel.proportionRangeTable}
                imgSrc={panel.proportionRangeTable.file.signedUrl}
                refetchQueries={refetchQueries}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SemiVariograms({
  panel,
  refetchQueries,
}: {
  panel: Panel;
  refetchQueries: RefetchQueries;
}) {
  return (
    <div className="space-y-4">
      <div>
        <Heading level={3}>Semi-Variograms</Heading>
      </div>

      <div className="grid lg:grid-cols-12 gap-4">
        <div className="lg:col-span-6">
          {panel.horizontalVariograms && (
            <div>
              <PictureThumbnailV4
                picture={panel.horizontalVariograms}
                imgSrc={panel.horizontalVariograms.file.signedUrl}
                refetchQueries={refetchQueries}
              />
              <p>Horizontal</p>
            </div>
          )}
        </div>
        <div className="lg:col-span-6">
          {panel.verticalVariograms && (
            <div>
              <PictureThumbnailV4
                picture={panel.verticalVariograms}
                imgSrc={panel.verticalVariograms.file.signedUrl}
                refetchQueries={refetchQueries}
              />
              <p>Vertical</p>
            </div>
          )}
        </div>
        <div className="lg:col-span-6">
          {panel.variogramParameterTable && (
            <div>
              <PictureThumbnailV4
                picture={panel.variogramParameterTable}
                imgSrc={panel.variogramParameterTable.file.signedUrl}
                refetchQueries={refetchQueries}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function TransitionStatistics({
  panel,
  refetchQueries,
}: {
  panel: Panel;
  refetchQueries: RefetchQueries;
}) {
  return (
    <div className="space-y-4">
      <div>
        <Heading level={3}>Transitions</Heading>
        <p>
          Record proportion of transitions from class shown across bottom to
          other classes
        </p>
      </div>

      {panel.transitionStatistics && (
        <div>
          <PictureThumbnailV4
            picture={panel.transitionStatistics}
            imgSrc={panel.transitionStatistics.file.signedUrl}
            refetchQueries={refetchQueries}
          />
        </div>
      )}
    </div>
  );
}
