import { z } from 'zod';
import type { PanelPartsFragment } from '~/apollo/generated/v4/graphql';
import {
  LifecycleStatus,
  PanelOrientation,
} from '~/apollo/generated/v4/graphql';
import { ppNumberOrNull, ppStringOrNull } from '~/utils/zod';

export const panelSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  author: z.string().min(1),
  height: z.preprocess(ppNumberOrNull, z.number().int().positive()),
  width: z.preprocess(ppNumberOrNull, z.number().int().positive()),
  panelOrientation: z.nativeEnum(PanelOrientation),
  comments: z.preprocess(ppStringOrNull, z.string().nullable()),
  priority: z.preprocess(ppNumberOrNull, z.number().int().nullable()),
  lifecycleStatus: z.nativeEnum(LifecycleStatus),
});

export type PanelFormValues = {
  name: string;
  description: string;
  author: string;
  height: string;
  width: string;
  panelOrientation: PanelOrientation;
  comments: string;
  priority: string;
  lifecycleStatus: LifecycleStatus;
};

export function initialPanel(panel?: PanelPartsFragment): PanelFormValues {
  return {
    name: panel?.name ?? '',
    description: panel?.description ?? '',
    author: panel?.author ?? '',
    height: String(panel?.height ?? ''),
    width: String(panel?.width ?? ''),
    panelOrientation: panel?.panelOrientation ?? PanelOrientation.Undefined,
    comments: panel?.comments ?? '',
    priority: String(panel?.priority ?? ''),
    lifecycleStatus: panel?.lifecycleStatus ?? LifecycleStatus.Draft,
  };
}
