import { z } from 'zod';
import type {
  MiniModelPartsFragment,
  Photo360PartsFragment,
  SoManagerCrossSectionPartsFragment,
  SoManagerFaciesPartsFragment,
  SoManagerGigaPanPartsFragment,
  SoManagerPicturePartsFragment,
  SoManagerProductionPartsFragment,
  SoManagerReservoirModelPartsFragment,
  SoManagerSedimentaryLogPartsFragment,
  SoManagerTrainingImagePartsFragment,
  SoManagerVariogramPartsFragment,
  SoManagerWellLogPartsFragment,
  VideoPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { ppNumberOrNull, ppStringOrNull } from '~/utils/zod';

export async function postFile(path: string, token: string, file: File) {
  const body = new FormData();
  body.set('file_data', file);
  body.set('token', token);

  const result = await fetch(path, {
    method: 'post',
    body,
  });

  return result.status === 200;
}

export const pictureSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  type: z.preprocess(ppStringOrNull, z.string().nullish()),
  author: z.preprocess(ppStringOrNull, z.string().nullish()),
  scale: z.preprocess(ppStringOrNull, z.string().nullish()),
  comments: z.preprocess(ppStringOrNull, z.string().nullish()),
  outcropTagId: z.preprocess(ppNumberOrNull, z.number().int().nullish()),
  priority: z.preprocess(ppNumberOrNull, z.number().int().nullish()),
  lifecycleStatus: z.nativeEnum(LifecycleStatus),
});
export type PictureUpdateFormValues = {
  name: string;
  description: string;
  type: string;
  author: string;
  scale: string;
  comments: string;
  outcropTagId: number | '';
  priority: string;
  lifecycleStatus: string;
};
export function initialPicture(
  picture?: SoManagerPicturePartsFragment,
): PictureUpdateFormValues {
  return {
    name: picture?.name ?? '',
    description: picture?.description ?? '',
    type: picture?.type ?? '',
    author: picture?.author ?? '',
    scale: picture?.scale ?? '',
    comments: picture?.comments ?? '',
    outcropTagId: picture?.outcropTagId ?? '',
    priority: String(picture?.priority ?? ''),
    lifecycleStatus: picture?.lifecycleStatus ?? LifecycleStatus.Draft,
  };
}

const schemas = {
  name: z.string().min(1),
  description: z.string().min(1),
  author: z.preprocess(ppStringOrNull, z.string().nullish()),
  scale: z.preprocess(ppStringOrNull, z.string().nullish()),
  comments: z.preprocess(ppStringOrNull, z.string().nullish()),
  interpretation: z.preprocess(ppStringOrNull, z.string().nullish()),
  gigaPanHash: z.preprocess(ppStringOrNull, z.string().nullish()),
  nugget: z.preprocess(ppNumberOrNull, z.number().nullish()),
  sill: z.preprocess(ppNumberOrNull, z.number().nullish()),
  range: z.preprocess(ppNumberOrNull, z.number().nullish()),
  published: z.boolean(),
  priority: z.preprocess(ppNumberOrNull, z.number().int().nullish()),
  outcropTagId: z.preprocess(ppNumberOrNull, z.number().int().nullish()),
};

export const crossSectionSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  scale: schemas.scale,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type CrossSectionFormValues = {
  name: string;
  description: string;
  author: string;
  scale: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialCrossSection(
  cs?: SoManagerCrossSectionPartsFragment,
): CrossSectionFormValues {
  return {
    name: cs?.name ?? '',
    description: cs?.description ?? '',
    author: cs?.author ?? '',
    scale: cs?.scale ?? '',
    comments: cs?.comments ?? '',
    priority: String(cs?.priority ?? ''),
    outcropTagId: cs?.outcropTagId ?? '',
  };
}

export const faciesSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  interpretation: schemas.interpretation,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type FaciesFormValues = {
  name: string;
  description: string;
  author: string;
  interpretation: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialFacies(
  f?: SoManagerFaciesPartsFragment,
): FaciesFormValues {
  return {
    name: f?.name ?? '',
    description: f?.description ?? '',
    author: f?.author ?? '',
    interpretation: f?.interpretation ?? '',
    comments: f?.comments ?? '',
    priority: String(f?.priority ?? ''),
    outcropTagId: f?.outcropTagId ?? '',
  };
}

// Same fields as cross section
export const sedlogSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  scale: schemas.scale,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type SedlogFormValues = {
  name: string;
  description: string;
  author: string;
  scale: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialSedlog(
  s?: SoManagerSedimentaryLogPartsFragment,
): SedlogFormValues {
  return {
    name: s?.name ?? '',
    description: s?.description ?? '',
    author: s?.author ?? '',
    scale: s?.scale ?? '',
    comments: s?.comments ?? '',
    priority: String(s?.priority ?? ''),
    outcropTagId: s?.outcropTagId ?? '',
  };
}

export const wellLogSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type WellLogFormValues = {
  name: string;
  description: string;
  author: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialWellLog(
  wl?: SoManagerWellLogPartsFragment,
): WellLogFormValues {
  return {
    name: wl?.name ?? '',
    description: wl?.description ?? '',
    author: wl?.author ?? '',
    comments: wl?.comments ?? '',
    priority: String(wl?.priority ?? ''),
    outcropTagId: wl?.outcropTagId ?? '',
  };
}

export const productionSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type ProductionFormValues = {
  name: string;
  description: string;
  author: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialProduction(
  p?: SoManagerProductionPartsFragment,
): ProductionFormValues {
  return {
    name: p?.name ?? '',
    description: p?.description ?? '',
    author: p?.author ?? '',
    comments: p?.comments ?? '',
    priority: String(p?.priority ?? ''),
    outcropTagId: p?.outcropTagId ?? '',
  };
}

export const reservoirModelSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type ReservoirModelFormValues = {
  name: string;
  description: string;
  author: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialReservoirModel(
  rm?: SoManagerReservoirModelPartsFragment,
): ReservoirModelFormValues {
  return {
    name: rm?.name ?? '',
    description: rm?.description ?? '',
    author: rm?.author ?? '',
    comments: rm?.comments ?? '',
    priority: String(rm?.priority ?? ''),
    outcropTagId: rm?.outcropTagId ?? '',
  };
}

export const trainingImageSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  comments: schemas.comments,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type TrainingImageFormValues = {
  name: string;
  description: string;
  author: string;
  comments: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialTrainingImage(
  ti?: SoManagerTrainingImagePartsFragment,
): TrainingImageFormValues {
  return {
    name: ti?.name ?? '',
    description: ti?.description ?? '',
    author: ti?.author ?? '',
    comments: ti?.comments ?? '',
    priority: String(ti?.priority ?? ''),
    outcropTagId: ti?.outcropTagId ?? '',
  };
}

export const variogramSchema = z.object({
  name: schemas.name,
  description: schemas.description,
  author: schemas.author,
  comments: schemas.comments,
  nugget: schemas.nugget,
  sill: schemas.sill,
  range: schemas.range,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type VariogramFormValues = {
  name: string;
  description: string;
  author: string;
  comments: string;
  nugget: string;
  sill: string;
  range: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialVariogram(
  v?: SoManagerVariogramPartsFragment,
): VariogramFormValues {
  return {
    name: v?.name ?? '',
    description: v?.description ?? '',
    author: v?.author ?? '',
    comments: v?.comments ?? '',
    nugget: String(v?.nugget ?? ''),
    sill: String(v?.sill ?? ''),
    range: String(v?.range ?? ''),
    priority: String(v?.priority ?? ''),
    outcropTagId: v?.outcropTagId ?? '',
  };
}

export const gigaPanSchema = z.object({
  name: schemas.name,
  gigaPanHash: schemas.gigaPanHash,
  priority: schemas.priority,
  outcropTagId: schemas.outcropTagId,
});
export type GigaPanFormValues = {
  name: string;
  gigaPanHash: string;
  priority: string;
  outcropTagId: number | '';
};
export function initialGigaPan(
  gp?: SoManagerGigaPanPartsFragment,
): GigaPanFormValues {
  return {
    name: gp?.name ?? '',
    gigaPanHash: gp?.gigaPanHash ?? '',
    priority: String(gp?.priority ?? ''),
    outcropTagId: gp?.outcropTagId ?? '',
  };
}

// Mini-models

export const miniModelSchema = z.object({
  name: z.string().min(1),
  url: z.string().url(),
  collectedBy: z.string().min(1),
  yearCollected: z.preprocess(ppNumberOrNull, z.number().int().positive()),
  equipment: z.string().min(1),
  scaniverseName: z.string().min(1),
  sketchfabName: z.preprocess(ppStringOrNull, z.string().nullable()),
  comments: z.preprocess(ppStringOrNull, z.string().nullable()),
  description: z.preprocess(ppStringOrNull, z.string().nullable()),
  latitude: z.preprocess(
    ppNumberOrNull,
    z.number().min(-90).max(90).nullable(),
  ),
  longitude: z.preprocess(
    ppNumberOrNull,
    z.number().min(-180).max(180).nullable(),
  ),
  lifecycleStatus: z.nativeEnum(LifecycleStatus),
  architecturalElements: z.array(z.string().min(1)).nullable(),
  priority: z.preprocess(ppNumberOrNull, z.number().int().nullable()),
});

export type MiniModelFormValues = {
  name: string;
  url: string;
  collectedBy: string;
  yearCollected: string;
  equipment: string | null;
  scaniverseName: string;
  sketchfabName: string;
  comments: string;
  description: string;
  latitude: string;
  longitude: string;
  architecturalElements: string[];
  lifecycleStatus: string;
  priority: string;
};

export function initialMiniModel(
  mm?: MiniModelPartsFragment,
): MiniModelFormValues {
  return {
    name: mm?.name ?? '',
    url: mm?.url ?? '',
    collectedBy: mm?.collectedBy ?? '',
    yearCollected: String(mm?.yearCollected ?? ''),
    equipment: mm?.equipment ?? null,
    scaniverseName: mm?.scaniverseName ?? '',
    sketchfabName: mm?.sketchfabName ?? '',
    comments: mm?.comments ?? '',
    description: mm?.description ?? '',
    latitude: String(mm?.latitude ?? ''),
    longitude: String(mm?.longitude ?? ''),
    lifecycleStatus: mm?.lifecycleStatus ?? LifecycleStatus.Draft,
    architecturalElements: mm?.architecturalElements ?? [],
    priority: String(mm?.priority ?? ''),
  };
}

// 360 Photos

export const photo360Schema = z.object({
  name: z.string().min(1),
  url: z.string().url(),
  collectedBy: z.string().min(1),
  equipment: z.string().min(1),
  yearCollected: z.preprocess(ppNumberOrNull, z.number().int().positive()),
});
export type Photo360FormValues = {
  name: string;
  url: string;
  collectedBy: string;
  yearCollected: string;
  equipment: string;
};
export function initialPhoto360(
  p?: Omit<Photo360PartsFragment, '__typename'>,
): Photo360FormValues {
  return {
    name: p?.name ?? '',
    url: p?.url ?? '',
    collectedBy: p?.collectedBy ?? '',
    yearCollected: p?.yearCollected.toString() ?? '',
    equipment: p?.equipment ?? '',
  };
}

// Videos
// (same as 360 photos)
export const videoSchema = photo360Schema;
export type VideoFormValues = Photo360FormValues;
export function initialVideo(v?: VideoPartsFragment): VideoFormValues {
  return initialPhoto360(v);
}
