import { Controller, useFormContext } from 'react-hook-form';
import { PanelOrientation } from '~/apollo/generated/v4/graphql';
import { InputGroup } from '~/components/common/InputGroup';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import { LifecycleStatusSelect } from '~/components/upload/lifecycle/lifecycle-status-select';
import type { PanelFormValues } from '~/utils/modules/panel';
import { readableGqlEnum } from '~/utils/text';

export function PanelFormFields() {
  const { register, control } = useFormContext<PanelFormValues>();

  return (
    <div className="space-y-2">
      <TextInput {...register('name')} label="Name" required />
      <TextInput {...register('description')} label="Description" required />
      <TextInput {...register('author')} label="Author" required />
      <TextInput
        {...register('height')}
        type="number"
        step="1"
        label="Height"
        required
        renderInput={input => (
          <InputGroup>
            {input}
            <InputGroup.Addon right>meters</InputGroup.Addon>
          </InputGroup>
        )}
      />
      <TextInput
        {...register('width')}
        type="number"
        step="1"
        label="Width"
        required
        renderInput={input => (
          <InputGroup>
            {input}
            <InputGroup.Addon right>meters</InputGroup.Addon>
          </InputGroup>
        )}
      />
      <Select
        {...register('panelOrientation')}
        label="Orientation"
        required
        options={Object.values(PanelOrientation).map(value => ({
          value,
          label: readableGqlEnum(value),
        }))}
        withBlankOption={false}
      />
      <TextInput {...register('comments')} label="Comments" />
      <TextInput
        {...register('priority')}
        type="number"
        step="1"
        label="Priority"
      />
      <Controller
        control={control}
        name="lifecycleStatus"
        render={({ field }) => (
          <LifecycleStatusSelect {...field} label="Status" required />
        )}
      />
    </div>
  );
}
