import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { cn } from '~/utils/common';

export type MenuProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  direction?: 'vertical' | 'horizontal';
  bordered?: boolean;
} & ComponentPropsWithoutRef<'ul'>;

export function Menu({
  size,
  direction,
  children,
  bordered,
  className,
  ...ulProps
}: MenuProps) {
  return (
    <ul
      {...ulProps}
      className={cn(
        'menu w-full',
        {
          'menu-horizontal': direction === 'horizontal',
          'menu-vertical': direction === 'vertical' || !direction,
          'menu-xs': size === 'xs',
          'menu-sm': size === 'sm',
          'menu-md': size === 'md',
          'menu-lg': size === 'lg',
          'border-t border-b border-slate-300 pb-4': bordered,
        },
        className,
      )}
    >
      {children}
    </ul>
  );
}

export type MenuItemProps = (
  | {
      collapsible?: false;
      title?: never;
    }
  | {
      collapsible: true;
      title: ReactNode;
    }
) &
  ({
    disabled?: boolean;
  } & ComponentPropsWithoutRef<'li'>);

export function MenuItem({
  disabled,
  collapsible,
  title,
  children,
  className,
  ...liProps
}: MenuItemProps) {
  return (
    <li
      {...liProps}
      className={cn('menu-item', { disabled: disabled }, className)}
    >
      {!collapsible ? (
        children
      ) : (
        <details open>
          <summary>{title}</summary>
          {children}
        </details>
      )}
    </li>
  );
}

export type MenuTitleProps = {} & ComponentPropsWithoutRef<'li'>;

export function MenuTitle({ children, className, ...liProps }: MenuTitleProps) {
  return (
    <li {...liProps} className={cn('menu-title', className)}>
      {children}
    </li>
  );
}

export function Submenu(props: ComponentPropsWithoutRef<'ul'>) {
  return <ul {...props} />;
}

Menu.Item = MenuItem;
Menu.Title = MenuTitle;
Menu.Submenu = Submenu;
