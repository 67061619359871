import { useMutation, useQuery } from '@apollo/client';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExifReader from 'exifreader';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { v4 } from 'uuid';
import { z } from 'zod';
import { graphql } from '~/apollo/generated/v4';
import type { PointInput } from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { DefaultValuesFormV4 } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/default-values-form';
import { PictureDropzone } from '~/components/upload/supporting-object/picture-dropzone';
import { FieldPictureMetadataFields } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/field-picture-metadata-fields';
import { useZodForm } from '~/utils/forms';
import { ppStringOrNull } from '~/utils/zod';

const FIELD_PICS_OUTCROP_LIST = graphql(`
  query FieldPicsOutcropList {
    outcropList(first: 9999) {
      results {
        id
        name
        center {
          lat
          lng
        }
      }
    }
  }
`);

const CREATE_FIELD_PICTURE = graphql(`
  mutation CreateFieldPicture($input: FieldPictureCreateInput!) {
    fieldPictureCreate(input: $input) {
      token
      path
    }
  }
`);

async function uploadFile(path: string, token: string, file: File) {
  const body = new FormData();
  body.set('file_data', file);
  body.set('token', token);

  const result = await fetch(path, {
    method: 'post',
    body,
  });

  return result.status === 200;
}

export type UploadQueueItem = {
  tmpId: string;
  file: File;
  exifLocation: PointInput | null;
  state: 'pending' | 'uploading' | 'success' | 'failed';
};

export type BulkFieldPictureUploaderFormValues = {
  defaults: {
    author: string;
    outcropId: number | null;
    location: PointInput | null;
  };
  metadata: Array<{
    tmpId: string;
    outcropId: number | null;
    author: string;
    description: string;
    location: PointInput | null;
    locationApproximate: boolean;
  }>;
};

function initialValues(outcropId?: number): BulkFieldPictureUploaderFormValues {
  return {
    defaults: {
      author: '',
      outcropId: outcropId ?? null,
      location: null,
    },
    metadata: [],
  };
}

function initialMetadata(
  item: UploadQueueItem,
  defaultLocation: PointInput | null,
): BulkFieldPictureUploaderFormValues['metadata'][number] {
  return {
    tmpId: item.tmpId,
    outcropId: null,
    author: '',
    description: '',
    location: item.exifLocation ?? defaultLocation ?? null,
    // Default to approximate location only if it uses the default location
    locationApproximate: item.exifLocation
      ? false
      : defaultLocation
        ? true
        : false,
  };
}

const bulkUploadSchema = z.object({
  metadata: z.array(
    z.object({
      tmpId: z.string().min(1),
      outcropId: z.number().int().positive(),
      author: z.preprocess(ppStringOrNull, z.string().nullish()),
      description: z.preprocess(ppStringOrNull, z.string().nullish()),
      location: z.object({
        latitude: z.number(),
        longitude: z.number(),
      }),
      locationApproximate: z.boolean(),
    }),
  ),
});
type ValidatedItemMetadata = z.infer<
  typeof bulkUploadSchema
>['metadata'][number];

async function readExifLocation(file: File): Promise<PointInput | null> {
  const exifData = await ExifReader.load(file, {
    // Required to read GPS data
    expanded: true,
  });
  const latitude = exifData.gps?.Latitude;
  const longitude = exifData.gps?.Longitude;

  if (!latitude || !longitude) {
    return null;
  }

  return { latitude, longitude };
}

type BulkFieldPictureUploaderProps = {
  outcropId?: number;
  onUploadSuccess?: () => void;
};

export function BulkFieldPictureUploader({
  outcropId,
  onUploadSuccess,
}: BulkFieldPictureUploaderProps) {
  const { data } = useQuery(FIELD_PICS_OUTCROP_LIST);
  const [createFieldPicture] = useMutation(CREATE_FIELD_PICTURE);

  const [isUploading, setIsUploading] = useState(false);
  const [queue, setQueue] = useState<UploadQueueItem[]>([]);

  const form = useZodForm({
    schema: bulkUploadSchema,
    values: initialValues(outcropId),
  });

  const fieldArray = useFieldArray({ name: 'metadata', control: form.control });

  const outcrops = R.sortBy(oc => oc.name, data?.outcropList?.results ?? []);

  const { setValue } = form;
  useEffect(() => {
    const outcropList = data?.outcropList?.results ?? [];
    if (outcropId && outcropList.length) {
      const defaultOutcrop = outcropList.find(
        oc => parseInt(oc.id) === outcropId,
      );
      if (defaultOutcrop && defaultOutcrop.center) {
        setValue('defaults.author', '');
        setValue('defaults.outcropId', parseInt(defaultOutcrop.id));
        setValue('defaults.location', {
          latitude: defaultOutcrop.center.lat,
          longitude: defaultOutcrop.center.lng,
        });
      } else {
        window.alert(
          "The selected outcrop doesn't have a center point set and cannot be used.",
        );
      }
    }
  }, [data?.outcropList, outcropId, setValue]);

  const defaultLocation = form.watch('defaults.location');
  async function handleAdd(moreFiles: File[]) {
    const nextItems: UploadQueueItem[] = [];

    for (const file of moreFiles) {
      const exifLocation = await readExifLocation(file);
      nextItems.push({
        file,
        tmpId: v4(),
        state: 'pending',
        exifLocation: exifLocation,
      });
    }

    setQueue([...queue, ...nextItems]);
    nextItems.forEach(item =>
      fieldArray.append(initialMetadata(item, defaultLocation)),
    );
  }

  function handleRemove(tmpId: string, index: number) {
    if (isUploading) {
      console.log("Can't remove items while upload in progress!");
      return;
    }

    const item = queue.find(item => item.tmpId === tmpId);
    if (item?.state === 'pending' || item?.state === 'failed') {
      const message =
        "This item hasn't been uploaded yet, are you sure you want to remove it?";
      if (!window.confirm(message)) {
        console.log('Removal cancelled');
        return;
      }
    }

    setQueue(items => items.filter(item => item.tmpId !== tmpId));
    fieldArray.remove(index);
  }

  function setItemState(tmpId: string, state: UploadQueueItem['state']) {
    setQueue(prevQueue =>
      prevQueue.map(qi => {
        if (qi.tmpId === tmpId) {
          return { ...qi, state };
        } else {
          return qi;
        }
      }),
    );
  }

  async function uploadItem(file: File, metadata: ValidatedItemMetadata) {
    const { tmpId, ...fieldPicture } = metadata;
    const createRes = await createFieldPicture({
      variables: {
        input: {
          fieldPicture,
        },
      },
    });

    const token = createRes.data?.fieldPictureCreate.token;
    const path = createRes.data?.fieldPictureCreate.path;
    invariant(token && path, 'fieldPictureCreate failed');

    return uploadFile(path, token, file);
  }

  const handleStartUploading = form.handleSubmit(async formValues => {
    const successes: string[] = [];
    const failures: string[] = [];

    setIsUploading(true);

    const itemsToUpload = queue.filter(
      item => item.state === 'pending' || item.state === 'failed',
    );

    for (const item of itemsToUpload) {
      setItemState(item.tmpId, 'uploading');

      const metadata = formValues.metadata.find(md => md.tmpId === item.tmpId);
      if (!metadata) {
        console.error(`Couldn't find item metadata ${item.tmpId}!`);
        setItemState(item.tmpId, 'failed');
        continue;
      }

      try {
        const success = await uploadItem(item.file, metadata);
        if (!success) throw new Error('File upload failed.');
        setItemState(item.tmpId, 'success');
        successes.push(item.tmpId);
      } catch (err) {
        console.error(err);
        console.log('Error uploading item:', item);
        setItemState(item.tmpId, 'failed');
        failures.push(item.tmpId);
      } finally {
        if (onUploadSuccess) onUploadSuccess();
      }
    }

    setIsUploading(false);
    toast.info(
      `Finished uploading with ${successes.length} successful and ${failures.length} failed.`,
    );
  });

  const hasItemsToUpload =
    queue.filter(item => item.state === 'pending' || item.state === 'failed')
      .length > 0;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleStartUploading}>
        <div className="space-y-4">
          <PictureDropzone hasFiles={queue.length > 0} onDrop={handleAdd} />

          {queue.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-2">
                <span>
                  {queue.length} picture{queue.length === 1 ? '' : 's'} selected
                </span>

                <Confirm
                  onConfirm={() => setQueue([])}
                  text="Are you sure you want to clear all images? You'll lose any unsaved changes."
                >
                  {toggleConfirm => (
                    <Button
                      type="button"
                      color="ghost"
                      size="sm"
                      onClick={toggleConfirm}
                      startIcon={<FontAwesomeIcon icon={faRefresh} />}
                    >
                      Start Over
                    </Button>
                  )}
                </Confirm>
              </div>

              <div className="space-y-4">
                <DefaultValuesFormV4
                  outcrops={outcrops}
                  initialOutcropId={outcropId}
                />

                {fieldArray.fields.map((field, index) => {
                  const item = queue.find(q => q.tmpId === field.tmpId);
                  if (!item) return null;
                  return (
                    <FieldPictureMetadataFields
                      key={field.id}
                      item={item}
                      index={index}
                      initialOutcropId={outcropId}
                      outcrops={outcrops}
                      onRemove={handleRemove}
                    />
                  );
                })}

                <HookFormErrors />

                <div className="text-center">
                  <Button
                    type="button"
                    onClick={handleStartUploading}
                    color="primary"
                    disabled={!hasItemsToUpload}
                    loading={isUploading}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
