import { useQuery } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { SoManagerPicturePartsFragment } from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Alert } from '~/components/ui/alert';
import {
  CollapsibleCard,
  useControlledCollapsibleCard,
} from '~/components/ui/collapse';
import { PanelPictureUploader } from '~/components/upload/panel/panel-picture-uploader';
import { UpdatePanelForm } from '~/components/upload/panel/update-panel-form';
import { SOPictureCard } from '~/components/upload/supporting-object/so-picture-card';
import { useRouteParam } from '~/hooks/routing';
import type { RefetchQueries } from '~/utils/graphql';
import { createRefetchQueries } from '~/utils/graphql';

const UPLOAD_PANEL_UPDATE_PAGE = graphql(`
  query UploadPanelUpdatePage($outcropId: ID!) {
    outcropGet(id: $outcropId) {
      id
      panels {
        author
        comments
        description
        id
        panelOrientation
        priority
        height
        lifecycleStatus
        name
        width
        elementProportion {
          ...soManagerPictureParts
        }
        horizontalVariograms {
          ...soManagerPictureParts
        }
        interpretedPanel {
          ...soManagerPictureParts
        }
        lateralProportionCurve {
          ...soManagerPictureParts
        }
        legendKey {
          ...soManagerPictureParts
        }
        originalPanel {
          ...soManagerPictureParts
        }
        proportionRangeTable {
          ...soManagerPictureParts
        }
        proportionRangeVertical {
          ...soManagerPictureParts
        }
        rockCleanedPanel {
          ...soManagerPictureParts
        }
        transitionStatistics {
          ...soManagerPictureParts
        }
        variogramParameterTable {
          ...soManagerPictureParts
        }
        verticalProportionCurve {
          ...soManagerPictureParts
        }
        verticalVariograms {
          ...soManagerPictureParts
        }
      }
    }
  }
`);

export default function UploadPanelUpdatePage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const panelId = useRouteParam('panelId', parseInt);

  const [searchParams] = useSearchParams();
  const defaultUploadOpen = searchParams.get('upload') === 't';

  const uploadCardControl = useControlledCollapsibleCard(defaultUploadOpen);

  const { data, loading } = useQuery(UPLOAD_PANEL_UPDATE_PAGE, {
    variables: { outcropId },
  });

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_PANEL_UPDATE_PAGE,
      variables: { outcropId },
    },
  ]);

  const panel = data?.outcropGet?.panels.find(p => parseInt(p.id) === panelId);

  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId/panels/$panelId',
    panel?.name ?? 'Update Panel',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!panel) return <NotFound />;

  return (
    <div className="space-y-4">
      <PageHeading>{panel.name}</PageHeading>

      <CollapsibleCard title="Panel Details">
        <UpdatePanelForm panel={panel} />
      </CollapsibleCard>

      <CollapsibleCard title="Upload Images" {...uploadCardControl}>
        <PanelPictureUploader
          panelId={panelId}
          refetchQueries={refetchQueries}
        />
      </CollapsibleCard>

      <PanelImageCard
        title="Element proportion"
        picture={panel.elementProportion}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Horizontal variograms"
        picture={panel.horizontalVariograms}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Interpreted panel"
        picture={panel.interpretedPanel}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Lateral proportion curve"
        picture={panel.lateralProportionCurve}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Legend key"
        picture={panel.legendKey}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Original panel"
        picture={panel.originalPanel}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Proportion range table"
        picture={panel.proportionRangeTable}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Proportion range vertical"
        picture={panel.proportionRangeVertical}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Rock cleaned panel"
        picture={panel.rockCleanedPanel}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Transition statistics"
        picture={panel.transitionStatistics}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Variogram parameter table"
        picture={panel.variogramParameterTable}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Vertical proprtion curve"
        picture={panel.verticalProportionCurve}
        refetchQueries={refetchQueries}
      />
      <PanelImageCard
        title="Vertical variograms"
        picture={panel.verticalVariograms}
        refetchQueries={refetchQueries}
      />
    </div>
  );
}

function PanelImageCard({
  title,
  picture,
  refetchQueries,
}: {
  title: string;
  picture: SoManagerPicturePartsFragment | undefined | null;
  refetchQueries: RefetchQueries;
}) {
  return (
    <div>
      <Heading level={3}>{title}</Heading>
      {picture ? (
        <SOPictureCard picture={picture} refetchQueries={refetchQueries} />
      ) : (
        <Alert status="error">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-2xl" />
          <div>Picture not set.</div>
        </Alert>
      )}
    </div>
  );
}
