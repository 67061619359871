import { useQuery } from '@apollo/client';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropPanelsPageQuery } from '~/apollo/generated/v4/graphql';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { PublishedContainer } from '~/components/common/Published';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { CollapsibleCard } from '~/components/ui/collapse';
import { CreatePanelForm } from '~/components/upload/panel/create-panel-form';
import { DeletePanel } from '~/components/upload/panel/delete-panel';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { TogglePublishedButton } from '~/components/upload/supporting-object/toggle-published-button';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { useRouteParam } from '~/hooks/routing';
import { uploadOutcropPanelUpdateRoute } from '~/paths';
import type { RefetchQueries } from '~/utils/graphql';
import { createRefetchQueries } from '~/utils/graphql';

const UPLOAD_OUTCROP_PANELS_PAGE = graphql(`
  query UploadOutcropPanelsPage($id: ID!) {
    outcropGet(id: $id) {
      id
      panels {
        ...panelParts
      }
    }
  }
`);

export default function UploadOutcropPanelsPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_PANELS_PAGE, {
    variables: { id: outcropId },
  });

  const refetchQueries = createRefetchQueries([
    { query: UPLOAD_OUTCROP_PANELS_PAGE, variables: { id: outcropId } },
  ]);

  const outcrop = data?.outcropGet;
  const allPanels = outcrop?.panels ?? [];
  const unpublishedCount = allPanels.reduce(
    (acc, panel) =>
      acc + (panel.lifecycleStatus !== LifecycleStatus.Published ? 1 : 0),
    0,
  );

  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const panels = R.pipe(
    () => allPanels,
    panels =>
      showUnpublished
        ? panels
        : panels.filter(p => p.lifecycleStatus === LifecycleStatus.Published),
    R.sortWith([R.ascend(p => p.priority ?? Infinity), R.ascend(p => p.name)]),
  )();

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <PublishedToggle />

      <CollapsibleCard title="Create Panel">
        <CreatePanelForm outcropId={outcropId} />
      </CollapsibleCard>

      <NoItemsAlert show={!panels.length} entity="panels" />
      {panels.map(panel => (
        <PanelItem
          key={panel.id}
          outcropId={outcropId}
          panel={panel}
          refetchQueries={refetchQueries}
        />
      ))}
    </div>
  );
}

type Outcrop = NonNullable<UploadOutcropPanelsPageQuery['outcropGet']>;
type Panel = Outcrop['panels'][number];

function PanelItem({
  outcropId,
  panel,
  refetchQueries,
}: {
  outcropId: number;
  panel: Panel;
  refetchQueries: RefetchQueries;
}) {
  return (
    <PublishedContainer
      published={panel.lifecycleStatus === LifecycleStatus.Published}
    >
      <Panel>
        <Panel.Heading>
          <div className="flex justify-between items-center gap-2">
            <Panel.Title>
              {panel.name} ({panel.id})
            </Panel.Title>

            <div className="shrink-0 space-x-1">
              <TogglePublishedButton entity={panel} />

              <DeletePanel
                panelId={parseInt(panel.id)}
                refetchQueries={refetchQueries}
              >
                {(confirmDelete, loading) => (
                  <Button
                    type="button"
                    onClick={confirmDelete}
                    color="ghost"
                    size="xs"
                    loading={loading}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeletePanel>

              <Link
                to={uploadOutcropPanelUpdateRoute(
                  outcropId,
                  parseInt(panel.id),
                )}
                className="btn btn-xs btn-primary"
              >
                <FontAwesomeIcon icon={faPencil} />
                Edit
              </Link>
            </div>
          </div>
        </Panel.Heading>
        <Panel.Body className="p-2">
          <SODetailText label="Author" value={panel.author} />
          <SODetailText label="Description" value={panel.description} />
          <SODetailText label="Comments" value={panel.comments} />
          <SODetailText label="Priority" value={panel.priority} />
        </Panel.Body>
      </Panel>
    </PublishedContainer>
  );
}
