import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { PanelFormFields } from '~/components/upload/panel/panel-form-fields';
import { uploadOutcropPanelUpdateRoute } from '~/paths';
import { useZodForm } from '~/utils/forms';
import { initialPanel, panelSchema } from '~/utils/modules/panel';

const CREATE_PANEL = graphql(`
  mutation CreatePanel($input: PanelCreateInput!) {
    panelCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreatePanelForm({ outcropId }: { outcropId: number }) {
  const [createPanel, { loading, error }] = useMutation(CREATE_PANEL);
  const navigate = useNavigate();

  const form = useZodForm({
    schema: panelSchema,
    values: initialPanel(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      const res = await createPanel({
        variables: { input: { ...values, outcropId } },
      });

      const panelId = res.data?.panelCreate?.result?.id;
      invariant(panelId, 'Something went wrong creating');

      toast.success('Panel created successfully.');
      const sp = new URLSearchParams();
      sp.set('upload', 't');
      navigate({
        pathname: uploadOutcropPanelUpdateRoute(outcropId, parseInt(panelId)),
        search: sp.toString(),
      });
    } catch (err) {
      console.log('Error creating panel', err);
      toast.error('There was a problem creating the panel.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <PanelFormFields />
        <HookFormErrors graphQLError={error} />

        <div className="text-right">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
