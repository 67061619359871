import type { ReactNode } from 'react';
import type { FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { cn } from '~/utils/common';
import { imageMimes } from '~/utils/formik';

export function PictureDropzone({
  hasFiles,
  onDrop,
  children,
}: {
  hasFiles: boolean;
  onDrop: (files: File[]) => unknown;
  children?: ReactNode;
}) {
  function handleRejects(rejects: FileRejection[]) {
    const names = rejects.map(r => r.file.name).join('\n');
    toast.error(`The following files weren't accepted:\n${names}`);
  }

  return (
    <Dropzone
      onDrop={onDrop}
      accept={imageMimes}
      multiple
      onDropRejected={handleRejects}
      maxSize={100_000_000}
    >
      {({ getRootProps, getInputProps }) => (
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className={cn('flex items-center gap-4', {
              'h-72': !hasFiles,
              'h-20': hasFiles,
            })}
            style={{ transition: 'height 0.5s' }}
          >
            {children ??
              'Drop pictures or folders of pictures here, or click to browse...'}
          </div>
        </DropzoneContainer>
      )}
    </Dropzone>
  );
}
