import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { panelDetailRoute } from '~/paths';
import { useOutcropPanelsOutletContext } from '~/routes/outcrop/$outcropId/panels';

export default function OutcropPanelsIndexPage() {
  const { outcropId, panels } = useOutcropPanelsOutletContext();

  return (
    <div className="space-y-4">
      {panels.map(panel => (
        <div key={panel.id} className="card shadow-sm">
          <div className="card-body">
            <h2 className="card-title">{panel.name}</h2>
            <div>Author: {panel.author}</div>
            <div>{panel.description}</div>
          </div>

          <figure>
            <Link
              to={panelDetailRoute(outcropId, parseInt(panel.id))}
              className="w-full h-72 relative overflow-hidden hover:border-2 hover:border-primary"
            >
              {panel.originalPanel ? (
                <img
                  src={panel.originalPanel.file.signedUrl}
                  className="absolute inset-0 w-full h-full object-cover"
                  alt="panel thumbnail"
                />
              ) : (
                <div className="w-full h-full bg-slate-50 flex justify-center items-center">
                  <FontAwesomeIcon icon={faImage} className="text-3xl" />
                </div>
              )}
            </Link>
          </figure>
        </div>
      ))}
    </div>
  );
}
