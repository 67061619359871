import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormState } from 'react-hook-form';
import { z } from 'zod';
import { Heading } from '~/components/common/Heading';
import { Alert } from '~/components/ui/alert';

const validationErrorsSchema_v3 = z.object({
  graphQLErrors: z.array(
    z.object({
      extensions: z.object({
        status: z.literal(422),
        originalError: z.object({
          message: z
            .array(
              z.object({
                path: z.string(),
                errors: z.array(z.string()),
              }),
            )
            .min(1),
        }),
      }),
    }),
  ),
});

const validationErrorsSchema_v4 = z.object({
  graphQLErrors: z.array(
    z.object({
      message: z.string(),
      fields: z.array(z.string()),
    }),
  ),
});

type ValidationErrorResponse_v3 = z.infer<typeof validationErrorsSchema_v3>;
type ValidationErrorResponse_v4 = z.infer<typeof validationErrorsSchema_v4>;

function parseValidationErrorsV3(gqlErrors: unknown) {
  const result = validationErrorsSchema_v3.safeParse(gqlErrors);
  if (result.success) return result.data;
  else return null;
}
function parseValidationErrorsV4(gqlErrors: unknown) {
  const result = validationErrorsSchema_v4.safeParse(gqlErrors);
  if (result.success) return result.data;
  else return null;
}

function ValidationErrorsV4({
  response,
}: {
  response: ValidationErrorResponse_v4;
}) {
  return (
    <ul className="list-disc list-inside pl-4">
      {response.graphQLErrors.map((error, i) => (
        <li key={`${error.fields.join('.')}[${i}]`}>
          <strong>{error.fields.join(' - ')}</strong>: {error.message}
        </li>
      ))}
    </ul>
  );
}

function ValidationErrorsV3({
  response,
}: {
  response: ValidationErrorResponse_v3;
}) {
  const errors = response.graphQLErrors.flatMap(
    gqlError => gqlError.extensions.originalError.message,
  );

  return (
    <ul className="list-none">
      {errors.map(error => (
        <li key={error.path}>
          <strong>{error.path}</strong>
          <ul className="list-disc list-inside pl-4">
            {error.errors.map(e => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}

export function HookFormErrors({
  condensed = false,
  graphQLError,
}: {
  condensed?: boolean;
  graphQLError?: unknown;
}) {
  const { errors: formErrors, submitCount } = useFormState();

  const validationErrorResponse_v3 = parseValidationErrorsV3(graphQLError);
  const validationErrorResponse_v4 = parseValidationErrorsV4(graphQLError);

  const hasFormErrors =
    formErrors && Object.keys(formErrors).length > 0 && submitCount > 0;

  const hasApiErrors_v3 = validationErrorResponse_v3 !== null;
  const hasApiErrors_v4 = validationErrorResponse_v4 !== null;

  if (!hasFormErrors && !graphQLError && !hasApiErrors_v3 && !hasApiErrors_v4) {
    return null;
  }

  console.log('Form errors:', formErrors);

  return (
    <Alert status="error">
      {!condensed && (
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-3xl" />
      )}
      <div>
        <Heading level={3}>
          {condensed && (
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          )}
          Error
        </Heading>

        {hasFormErrors && (
          <div className="text-error-content">
            There is a problem in one or more fields.
          </div>
        )}
        {typeof graphQLError !== 'undefined' && (
          <div className="text-error-content">
            There was a problem processing the request.
          </div>
        )}

        {hasApiErrors_v3 && (
          <ValidationErrorsV3 response={validationErrorResponse_v3} />
        )}
        {hasApiErrors_v4 && (
          <ValidationErrorsV4 response={validationErrorResponse_v4} />
        )}
      </div>
    </Alert>
  );
}
