import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import {
  FormProvider,
  useController,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { v4 } from 'uuid';
import { z } from 'zod';
import { graphql } from '~/apollo/generated/v4';
import type { PanelSetImageInput } from '~/apollo/generated/v4/graphql';
import { PanelPictureType } from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import { StatusIcon } from '~/components/upload/georeference/ImportKML/ConfirmData/StatusIcon';
import { PictureDropzone } from '~/components/upload/supporting-object/picture-dropzone';
import { PictureTypeSelect } from '~/components/upload/supporting-object/picture-type-select-v4';
import { useRefetchQueriesV4 } from '~/hooks/apollo';
import { cn } from '~/utils/common';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import { postFile } from '~/utils/modules/supporting-object';
import { readableGqlEnum } from '~/utils/text';
import { ppStringOrNull } from '~/utils/zod';

const prefixType: Record<string, PanelPictureType> = {
  OP: PanelPictureType.OriginalPanel,
  IP: PanelPictureType.InterpretedPanel,
  LK: PanelPictureType.LegendKey,
  RCP: PanelPictureType.RockCleanedPanel,
  EP: PanelPictureType.ElementProportion,
  LPC: PanelPictureType.LateralProportionCurve,
  VPC: PanelPictureType.VerticalProportionCurve,
  PRV: PanelPictureType.ProportionRangeVertical,
  PRT: PanelPictureType.ProportionRangeTable,
  HV: PanelPictureType.HorizontalVariograms,
  VV: PanelPictureType.VerticalVariograms,
  VPT: PanelPictureType.VariogramParameterTable,
  TS: PanelPictureType.TransitionStatistics,
};

function panelTypeByFilename(filename: string): PanelPictureType | undefined {
  const prefix = filename.split('_').at(0);
  return prefix ? prefixType[prefix] : undefined;
}

const CREATE_PANEL_IMAGE = graphql(`
  mutation CreatePanelImage($input: PanelSetImageInput!) {
    panelSetImage(input: $input) {
      path
      token
    }
  }
`);

const uploadStatuses = ['pending', 'uploading', 'success', 'failed'] as const;
type UploadStatus = (typeof uploadStatuses)[number];

const schema = z.object({
  defaultValues: z.object({
    author: z.string(),
    pictureType: z.string(),
  }),
  pictures: z.array(
    z.object({
      file: z.instanceof(File),
      uploadStatus: z.enum(uploadStatuses),
      type: z.nativeEnum(PanelPictureType),
      name: z.string().min(1),
      author: z.preprocess(ppStringOrNull, z.string().nullable()),
      pictureType: z.string().min(1),
    }),
  ),
});

type FormValues = {
  defaultValues: {
    author: string;
    pictureType: string;
  };
  pictures: Array<{
    tmpId: string;
    file: File;
    uploadStatus: UploadStatus;
    type: string;
    name: string;
    author: string;
    pictureType: string;
  }>;
};

const initialValues = (): FormValues => ({
  defaultValues: { author: '', pictureType: '' },
  pictures: [],
});

export function PanelPictureUploader({
  panelId,
  refetchQueries,
}: {
  panelId: number;
  refetchQueries: RefetchQueries;
}) {
  /** Cache of thumbnail URLs so they don't flicker on typing rerender */
  const [fileURLs, setFileURLs] = useState<Record<string, string>>({});
  const [createPanelImage] = useMutation(CREATE_PANEL_IMAGE);
  const [refetch] = useRefetchQueriesV4(refetchQueries);
  const [isUploading, setIsUploading] = useState(false);

  const form = useZodForm({
    schema,
    values: initialValues(),
  });
  const fieldArray = useFieldArray({
    control: form.control,
    name: 'pictures',
  });

  const defaultValues = form.watch('defaultValues');
  const pictures = form.watch('pictures');

  const setItemStatus = (index: number, status: UploadStatus) =>
    form.setValue(`pictures.${index}.uploadStatus`, status);

  async function uploadItem(
    index: number,
    picture: z.infer<typeof schema>['pictures'][number],
  ) {
    const input: PanelSetImageInput = {
      panelId,
      type: picture.type,
      picture: {
        name: picture.name,
        description: picture.name,
        author: picture.author,
        type: picture.pictureType,
      },
    };

    try {
      setItemStatus(index, 'uploading');
      const res = await createPanelImage({ variables: { input } });
      const createResult = res.data?.panelSetImage;
      const path = createResult?.path;
      const token = createResult?.token;
      invariant(path && token, 'Missing upload path or token');

      const uploadResult = await postFile(path, token, picture.file);
      invariant(uploadResult, 'File upload failed!');
      setItemStatus(index, 'success');
      return true;
    } catch (err) {
      console.log('Error uploading file', err);
      setItemStatus(index, 'failed');
      return false;
    }
  }

  type Picture = z.infer<typeof schema>['pictures'][number];
  const isPendingUpload = (p: Picture) =>
    p.uploadStatus === 'pending' || p.uploadStatus === 'failed';

  const handleSubmit = form.handleSubmit(async values => {
    const toUpload = values.pictures.filter(isPendingUpload);
    if (!toUpload.length) return;

    setIsUploading(true);

    let numFailed = 0;
    for (let i = 0; i < values.pictures.length; i++) {
      const picture = values.pictures[i];
      if (isPendingUpload(picture)) {
        const result = await uploadItem(i, picture);
        if (!result) numFailed++;
      }
    }

    const numSuccess = toUpload.length - numFailed;
    toast(
      `Upload completed with ${numSuccess} successful and ${numFailed} failed.`,
      { type: numFailed > 0 ? 'error' : 'success' },
    );

    await refetch();
    setIsUploading(false);
  });

  function handleDrop(droppedFiles: File[]) {
    const nextUrlCache = { ...fileURLs };
    for (const file of droppedFiles) {
      const tmpId = v4();
      nextUrlCache[tmpId] = URL.createObjectURL(file);

      fieldArray.append({
        file,
        tmpId,
        uploadStatus: 'pending',
        type: panelTypeByFilename(file.name) ?? '',
        name: file.name,
        author: defaultValues.author || '',
        pictureType: defaultValues.pictureType || 'data plot',
      });
    }
    setFileURLs(nextUrlCache);
  }

  function clearAll() {
    form.setValue('pictures', []);
  }

  const hasItemsToUpload = pictures.some(
    p => p.uploadStatus === 'pending' || p.uploadStatus === 'failed',
  );

  const isClearAllDisabled =
    !pictures.length || pictures.some(p => p.uploadStatus === 'uploading');

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <PictureDropzone onDrop={handleDrop} hasFiles={false}>
          <div className="text-center">
            Drop pictures or folders of pictures here, or click to browse.
            <br />
            Max file size: 100 MB
          </div>
          <div className="overflow-y-auto">
            {Object.keys(prefixType).map(prefix => (
              <div key={prefix} className="text-sm">
                <strong>{prefix}_</strong> {readableGqlEnum(prefixType[prefix])}
              </div>
            ))}
          </div>
        </PictureDropzone>

        <div className="space-y-2">
          <DefaultValuesForm />

          {pictures.map((picture, i) => (
            <div key={picture.tmpId} className="border border-slate-300 p-4">
              <div className="grid grid-cols-6 gap-4">
                <div className="space-y-2 text-center relative">
                  <div
                    className={cn(
                      'w-full aspect-square bg-cover bg-center shadow-sm flex items-center justify-center border-2',
                      {
                        'border-success': picture.uploadStatus === 'success',
                        'border-error': picture.uploadStatus === 'failed',
                        'border-slate-300':
                          picture.uploadStatus === 'pending' ||
                          picture.uploadStatus === 'uploading',
                      },
                    )}
                    style={{
                      backgroundImage: `url(${fileURLs[picture.tmpId]}`,
                    }}
                  >
                    {picture.uploadStatus !== 'pending' && (
                      <div className="bg-white/50 backdrop-blur-xs p-4 w-12 h-12 flex items-center justify-center btn-circle">
                        {picture.uploadStatus === 'uploading' ? (
                          <SpinnerPlaceholder />
                        ) : (
                          <StatusIcon uploadStatus={picture.uploadStatus} />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-5">
                  <Button
                    type="button"
                    onClick={() => {
                      fieldArray.remove(i);
                    }}
                    size="xs"
                    color="ghost"
                    disabled={isUploading}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                    className="float-right ml-2"
                  />
                  <Heading level={4}>{picture.file.name}</Heading>
                  <PictureFormFields index={i} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <HookFormErrors />

        <div className="text-center space-x-2">
          <Button
            type="button"
            onClick={clearAll}
            color="ghost"
            disabled={isClearAllDisabled}
          >
            Clear All
          </Button>
          <Button
            type="submit"
            color="primary"
            loading={isUploading}
            disabled={!hasItemsToUpload}
          >
            Upload Files
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

type PictureField = keyof FormValues['pictures'][number];

function DefaultValuesForm() {
  const { control, watch, setValue } = useFormContext<FormValues>();

  const authorControl = useController({
    control,
    name: 'defaultValues.author',
  });
  const pictureTypeControl = useController({
    control,
    name: 'defaultValues.pictureType',
  });

  const pictureFieldName = <TFieldName extends PictureField>(
    index: number,
    name: TFieldName,
  ) => `pictures.${index}.${name}` as const;

  const pictures = watch('pictures');

  function handleAuthorChange(event: ChangeEvent<HTMLInputElement>) {
    authorControl.field.onChange(event);
    pictures.forEach((_, i) => {
      setValue(pictureFieldName(i, 'author'), event.target.value);
    });
  }

  function handlePictureTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    pictureTypeControl.field.onChange(event);
    pictures.forEach((_, i) => {
      setValue(pictureFieldName(i, 'pictureType'), event.target.value);
    });
  }

  return (
    <div className="border border-slate-300 bg-slate-100 border-dotted p-4 space-y-2">
      <Heading level={3}>Default Values</Heading>

      <div className="grid lg:grid-cols-2 gap-4">
        <TextInput
          {...authorControl.field}
          onChange={handleAuthorChange}
          label="Default author"
        />
        <PictureTypeSelect
          {...pictureTypeControl.field}
          onChange={handlePictureTypeChange}
          label="Default picture type"
        />
      </div>
    </div>
  );
}

function PictureFormFields({ index }: { index: number }) {
  const { control } = useFormContext<FormValues>();

  const fieldName = <TFieldName extends PictureField>(name: TFieldName) =>
    `pictures.${index}.${name}` as const;

  const authorControl = useController({ control, name: fieldName('author') });
  const pictureTypeControl = useController({
    control,
    name: fieldName('pictureType'),
  });
  const typeControl = useController({
    control,
    name: fieldName('type'),
  });

  return (
    <div className="space-y-2">
      <div className="grid lg:grid-cols-2 gap-4">
        <TextInput {...authorControl.field} label="Author" />
        <PictureTypeSelect {...pictureTypeControl.field} label="Picture type" />
      </div>

      <Select
        {...typeControl.field}
        label="Type"
        options={Object.keys(prefixType).map(prefix => ({
          value: prefixType[prefix],
          label: `${readableGqlEnum(prefixType[prefix])} (${prefix})`,
        }))}
        required
      />
    </div>
  );
}
