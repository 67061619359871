import { Outlet } from 'react-router';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';

export default function UploadOutcropPanelsPageBreadcrumbWrapper() {
  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId/panels',
    'Analysed Panels',
  );

  return <Outlet />;
}
