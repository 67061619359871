import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { PanelPartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { PanelFormFields } from '~/components/upload/panel/panel-form-fields';
import { useZodForm } from '~/utils/forms';
import { initialPanel, panelSchema } from '~/utils/modules/panel';

export const UPDATE_PANEL = graphql(`
  mutation UpdatePanel($id: ID!, $input: PanelUpdateInput!) {
    panelUpdate(id: $id, input: $input) {
      result {
        ...panelParts
      }
    }
  }
`);

export function UpdatePanelForm({ panel }: { panel: PanelPartsFragment }) {
  const [updatePanel, { loading, error }] = useMutation(UPDATE_PANEL);

  const form = useZodForm({
    schema: panelSchema,
    values: initialPanel(panel),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updatePanel({
        variables: {
          id: panel.id,
          input: values,
        },
      });
      toast.success('Panel updated successfully.');
    } catch (err) {
      console.log('Error updating panel', err);
      toast.error('There was a problem updating the panel.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <PanelFormFields />
        <HookFormErrors graphQLError={error} />

        <div className="text-right">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
