import { useMutation } from '@apollo/client';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Confirm } from '~/components/common/Confirm';
import type { RefetchQueries } from '~/utils/graphql';

const DELETE_PANEL = graphql(`
  mutation DeletePanel($id: ID!) {
    panelDelete(id: $id) {
      result {
        id
      }
    }
  }
`);

export function DeletePanel({
  panelId,
  refetchQueries,
  children,
}: {
  panelId: number;
  refetchQueries: RefetchQueries;
  children: (confirmDelete: () => void, loading: boolean) => ReactNode;
}) {
  const [deletePanel, { loading }] = useMutation(DELETE_PANEL, {
    variables: { id: panelId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deletePanel();
      toast.info('Panel deleted.');
    } catch (err) {
      console.log('Error deleting panel', err);
      toast.error('There was a problem deleting the panel.');
    }
  }

  return (
    <Confirm
      text="The panel and its images will be permanently deleted."
      onConfirm={handleDelete}
    >
      {onConfirm => children(onConfirm, loading)}
    </Confirm>
  );
}
