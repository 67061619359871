import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import {
  LifecycleStatus,
  type FilePartsFragment,
  type PicturePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
// import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
// import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Modal } from '~/components/common/Modal';
import { PublishedContainer } from '~/components/common/Published';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { useRefetchQueriesV4 } from '~/hooks/apollo';
import { useModalState } from '~/hooks/modal';
import { cn, truncateText } from '~/utils/common';
import type { RefetchQueries } from '~/utils/graphql';

type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
};

type Props = {
  picture: Picture;
  imgSrc: string;
  fade?: boolean;
  containerClassName?: string;
  // bookmarkable?: TargetBookmarksManagerModalProps;
  showCaption?: boolean;
  refetchQueries: RefetchQueries;
  aspect?: 'square' | 'auto';
};
export function PictureThumbnailV4({
  picture,
  imgSrc,
  fade = false,
  containerClassName,
  // bookmarkable,
  showCaption,
  refetchQueries,
  aspect = 'auto',
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [shouldOpenInNewWindow, setShouldOpenInNewWindow] = useState(false);
  const [refetch] = useRefetchQueriesV4(refetchQueries);
  const signedUrl = useRef(picture.file.signedUrl);

  useEffect(() => {
    if (shouldOpenInNewWindow && signedUrl.current !== picture.file.signedUrl) {
      setShouldOpenInNewWindow(false);
      window.open(picture.file.signedUrl, '_blank');
    }

    signedUrl.current = picture.file.signedUrl;
  }, [picture.file.signedUrl, shouldOpenInNewWindow]);

  async function reloadAndOpenInNewWindow() {
    setShouldOpenInNewWindow(true);
    await refetch();
  }

  return (
    <>
      <div className={containerClassName}>
        <PublishedContainer
          published={picture.lifecycleStatus === LifecycleStatus.Published}
          withIcon
        >
          <a
            href="#enlarge-image"
            onClick={showModal}
            className={cn('hover:border-2 hover:border-primary', {
              'w-full aspect-square relative overflow-hidden':
                aspect === 'square',
              'fade-img': fade,
            })}
          >
            <img
              src={imgSrc}
              alt={picture.name}
              className={cn({
                'absolute inset-0 w-full h-full object-cover':
                  aspect === 'square',
              })}
            />
          </a>

          {picture && picture.description && showCaption && (
            <div className="caption text-center">
              {truncateText(picture.description, 50)}
            </div>
          )}
        </PublishedContainer>
      </div>

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading={picture.name ?? 'Picture'}>
          {/* {bookmarkable && (
            <div className="text-right">
              <TargetBookmarksManagerModal {...bookmarkable} />
            </div>
          )} */}

          <div className="w-full space-y-4">
            <div className="text-center space-y-0.5">
              <img
                src={imgSrc}
                alt={picture.name}
                className="mx-auto inline max-w-full"
              />
              <div className="text-right">
                <Button
                  type="button"
                  onClick={reloadAndOpenInNewWindow}
                  color="ghost"
                  size="sm"
                  endIcon={<FontAwesomeIcon icon={faExternalLink} />}
                  loading={shouldOpenInNewWindow}
                >
                  View in full resolution
                </Button>
              </div>
            </div>

            {picture && (
              <div>
                <SODetailText label="Description" value={picture.description} />
                <SODetailText label="Author" value={picture.author} />
                <SODetailText label="Type" value={picture.type} />
                <SODetailText label="Scale" value={picture.scale} />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
